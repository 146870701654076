<template>
  <div class="create-category-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Work' }">作品管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'WorkLocation' }"
        >展區管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>新增展區</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="workForm"
        :model="work_form"
        :rules="work_rules"
        label-position="top"
      >
        <el-form-item label="中文名稱" prop="name_zh">
          <el-input
            v-model="work_form.name_zh"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="英文名稱" prop="name_en">
          <el-input
            v-model="work_form.name_en"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getWorkLocation, updateWorkLocation } from "@/api/work-location";

export default {
  name: "EditWorkLocation",
  data() {
    return {
      work_form: {
        name_zh: "",
        name_en: "",
      },
      work_rules: {
        name_zh: [
          { required: true, message: "請填入名稱", trigger: "blur" },
          { max: 64, message: "長度太長", trigger: "blur" },
        ],
        name_en: [
          { required: true, message: "請填入名稱", trigger: "blur" },
          { max: 64, message: "長度太長", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    workLocationId() {
      return +this.$route.params.id;
    },
  },
  created() {
    this.handleGetWorkLocation();
  },
  methods: {
    async handleGetWorkLocation() {
      try {
        this.work_form = await getWorkLocation(this.workLocationId);
      } catch (e) {
        await this.$alert("輸入資料有誤，系統將強制跳轉頁面", "提醒", {
          confirmButtonText: "確定",
          type: "warning",
          callback: async () => {
            this.$message({
              type: "success",
              message: `跳轉成功`,
            });

            await this.handleRedirect();
          },
        });
      }
    },
    handleSubmit() {
      this.$refs.workForm.validate(async (valid) => {
        if (valid) {
          await updateWorkLocation(this.workLocationId, this.work_form);

          this.$message({
            type: "success",
            message: "編輯成功",
          });

          await this.handleRedirect();
        }
      });
    },
    async handleRedirect() {
      await this.$router.push({ name: "WorkLocation" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
